import React, { useEffect, useState } from 'react';
import styles from './feedback-details.container.module.scss';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { PUBLIC_PATH } from '../../../shared/config/routes-config';
import { useParams } from 'react-router';
import { getOneReservationsFeebacks, downloadFeedback } from '../../shared/services/data.service';
import { isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { FeedbackResponse } from '../../../shared/models/feedbacks.model';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';

/**
 * function feedback-details Container components
 */

export const FeedbackDetails: React.FC = () => {
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [data, setData] = useState<FeedbackResponse>({} as FeedbackResponse);
  const params: any = useParams();
  const isMobileView = useWindowResizer();

  useEffect(() => {
    getOneFeedbacks(params.id);
  }, []);

  function getOneFeedbacks(reservationId: string) {
    setLoadingData(true);
    getOneReservationsFeebacks(reservationId)
      .then((response) => {
        if (!isErrorInfo(response)) {
          setData(response);
        }
      })
      .finally(() => setLoadingData(false));
  }

  function downloadReservationFeedback(feedbackId: string) {
    setLoadingData(true);
    console.log(feedbackId);
    downloadFeedback(feedbackId)
      .then((response) => {
        console.log(response);
        if (!isErrorInfo(response)) {
          downloadCSV(response);
        }
      })
      .finally(() => setLoadingData(false));
  }

  function downloadCSV(file: any) {

    // Check if CSV data is empty
    // if (csvData === '') {
    //   alert('No data to export');
    // } else {
    // Create CSV file and initiate download
    // const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    var binaryData = [];
    binaryData.push(file);
    link.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/csv' }));

    // link.href = URL.createObjectURL(file);
    link.setAttribute('download', 'reservation_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // }
  }

  return (
    <div className={styles['feedback-details']}>
      <div className={styles['feedback-details__title']}>Feedback Details</div>
      {loadingData ? (
        <div className={styles['feedback-details__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <>
          <Button
            onClick={() => {
              downloadReservationFeedback(data.id);
            }}
            className={
              styles['reservation-list-container__reservation-item__button-container__btn']
            }
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            type={ButtonType.success}
          >
            export
          </Button>
          {data?.feedback?.map((item) => (
            <>
              <Accordion defaultExpanded>
                <AccordionSummary>
                  <Typography className={styles['feedback-details__question']}>
                    {item.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {item.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          ))}
          {((!data.feedback) || data?.feedback?.length === 0) && (
            <div className={styles['feedback-details__noData']}>
              <img src={`${PUBLIC_PATH}/assets/jpeg/no-records.jpg`} alt="no-data" /> No Data
              Available
            </div>
          )}
        </>
      )}
    </div>
  );
};
