import React, { useState } from 'react';
import styles from './add-payment-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  InputText,
  InputTextErrorMode,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import Select, { ValueType } from 'react-select';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { PaymentUIModel } from '../../../../../shared/ui-models/payment.ui-model';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { PaymentStatus } from '../../../../../shared/enums/payment-status.enum';
import { PaymentCollector } from '../../../../../shared/enums/payment-collector.enum';
import { PaymentType } from '../../../../../shared/enums/payment-type.enum';
import { ReservationDetailsUiModel } from '../../../../../shared/ui-models/reservation.ui-model';
import { FeeType } from '../../../../../shared/enums/amount-type.enum';
import { PaymentItemType } from '../../../../../shared/enums/payment-item-type.enum';
import { PaymentItemModel } from '../../../../../shared/models/payment-item.model';
import { hasAccess } from '../../../../../shared/services/helpers/auth-helper/auth-helper.service';
import { PERMISSIONS_LIST } from '../../../../../shared/config/permissions.config';

export interface AddPaymentModalProps {
  config: {
    showModal: boolean;
  };
  data: {
    reservation: ReservationDetailsUiModel | undefined;
  };
  eventHandlers: {
    closeHandler: () => void;
    addPaymentHandler: (paymentData: PaymentUIModel) => void;
  };
}

/**
 * functional component AddPaymentModal
 * @param {AddPaymentModalProps}
 */
export const AddPaymentModal: React.FC<AddPaymentModalProps> = ({
  config,
  eventHandlers,
  data
}) => {
  // const [newPaymentItem, setNewPaymentPaymentItem] = useState<FormElementData<string>>({
  //   value: 'Admin Generated',
  //   isValid: true
  // });
  const [newPaymentItems, setNewPaymentItems] = useState<PaymentItemModel[]>([
    {
      feeName: '',
      feeType: FeeType.AMOUNT,
      itemType: '',
      feeValue: 0
    }
  ]);
  // const [newPaymentAmount, setNewPaymentAmount] = useState<FormElementData<string>>({
  //   value: '',
  //   isValid: false
  // });

  const [newPaymentDueDate, setNewPaymentDueDate] = useState<FormElementData<Date>>({
    value: new Date(),
    isValid: true
  });
  const [newPaymentDirection, setNewPaymentDirection] = useState<FormElementData<'in' | 'out'>>({
    value: 'in',
    isValid: true
  });

  const [newPaymentCurrency, setNewPaymentCurrency] = useState<FormElementData<'egp' | 'usd'>>({
    value: 'egp',
    isValid: true
  });

  const isMobileView = useWindowResizer();

  const handleNewPaymentSubmit = () => {
    const newPayment: PaymentUIModel = {
      reservationId: String(data.reservation?.id) || '',
      unitTypeId: data.reservation?.unitTypeId || '',
      roomId: data.reservation?.roomId || '',
      dueDate: newPaymentDueDate.value?.getTime(),
      checkInDate: Number(data.reservation?.checkInDate),
      checkOutDate: Number(data.reservation?.checkOutDate),
      // paymentAmount: Number(newPaymentAmount.value),
      areaId: data.reservation?.area,
      userId: data.reservation?.userId,
      document: newPaymentItems,
      direction: newPaymentDirection.value,
      paymentItems: newPaymentItems,
      status: PaymentStatus.PENDING,
      collector: PaymentCollector.BIRDNEST,
      paymentType: PaymentType.CASH,
      unitTypeGroupId: String(data.reservation?.unitTypeGroupId),
      landlordId: String(data.reservation?.landlordId),
      propertyId: String(data.reservation?.propertyId),
      source: String(data.reservation?.source)
    };
    eventHandlers.addPaymentHandler(newPayment);
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, maxWidth: 'lg', showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['add-payment-modal']}>
        <div className={styles['add-payment-modal__title']}>Add payment</div>
        <hr className={styles['add-payment-modal__separator']} />
        {/* <div className={styles['add-payment-modal__description']}>
          Payment Item
          <InputText
            data={{
              defaultValue: newPaymentItem.value,
              errorMessage: 'First name is required',
              placeholder: 'Payment Item'
            }}
            config={{
              maxRows: 1,
              autoFocus: true,
              required: true,
              showErrorMode: InputTextErrorMode.instant
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setNewPaymentPaymentItem({ value, isValid });
              }
            }}
          />
        </div> */}
        {/* <div className={styles['add-payment-modal__description']}>
          Amount
          <InputText
            data={{
              defaultValue: '',
              errorMessage: 'amount is required',
              placeholder: 'Amount'
            }}
            config={{
              maxRows: 1,
              required: true,
              type: InputTextType.number,
              showErrorMode: InputTextErrorMode.instant
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setNewPaymentAmount({ value, isValid });
              }
            }}
          />
        </div> */}
        <div className={styles['add-payment-modal__split']}>
          <div className={styles['add-payment-modal__split__section']}>
            <label>Direction</label>
            <Select
              options={[
                { label: 'PAY-IN', value: 'in' },
                { label: 'PAY-OUT', value: 'out' }
              ]}
              defaultValue={{ label: 'PAY-IN', value: 'in' }}
              onChange={(value: ValueType<{ value: string; label: string }, false>) => {
                setNewPaymentDirection({
                  value: value ? (value.value as 'in' | 'out') : 'in',
                  isValid: true
                });
              }}
            />
          </div>
          <div className={styles['add-payment-modal__split__section']}>
            <label>Currency</label>
            <Select
              options={[
                { label: 'EGP', value: 'egp' },
                { label: '$', value: 'usd' }
              ]}
              defaultValue={{ label: 'EGP', value: 'egp' }}
              onChange={(value: ValueType<{ value: string; label: string }, false>) => {
                setNewPaymentCurrency({
                  value: value ? (value.value as 'egp' | 'usd') : 'egp',
                  isValid: true
                });
              }}
            />
          </div>
        </div>

        <div className={styles['add-payment-modal__split']}>
          <div className={styles['add-payment-modal__split__section']}>
            <label>Due Date</label>
            <ReactDatePicker
              selected={newPaymentDueDate.value}
              // minDate={moment().toDate()}
              onChange={(date) => setNewPaymentDueDate({ value: date as Date, isValid: true })}
            />
          </div>
          <div className={styles['add-payment-modal__split__section']}></div>
        </div>
        <div className={styles['add-payment-modal__items-wrapper']}>
          <div className={styles['add-payment-modal__items-wrapper__header']}>
            Payment Items
            <div
              className={styles['add-payment-modal__add']}
              onClick={() => {
                const tempPaymentItems = [...newPaymentItems];
                tempPaymentItems.push({
                  feeName: '',
                  feeType: FeeType.AMOUNT,
                  itemType: '',
                  feeValue: 0
                });
                setNewPaymentItems(tempPaymentItems);
              }}
            >
              <AddBoxIcon color="primary" fontSize="inherit" />
            </div>
          </div>
          <div className={styles['add-payment-modal__items-wrapper__item']}>
            <div className={styles['add-payment-modal__description']}>
              <label>Fee Name</label>
            </div>
            <div className={styles['add-payment-modal__description']}>
              <label>Payment Type</label>
            </div>
            <div className={styles['add-payment-modal__description']}>
              <label>Amount</label>
            </div>
          </div>
          {newPaymentItems.map((paymentItem, index) => (
            <div className={styles['add-payment-modal__items-wrapper__item']} key={index}>
              <div className={styles['add-payment-modal__description']}>
                <InputText
                  data={{
                    value: paymentItem.feeName,
                    errorMessage: 'Fee Name is required',
                    placeholder: 'Fee name'
                  }}
                  config={{
                    maxRows: 1,
                    autoFocus: true,
                    required: true,
                    showErrorMode: InputTextErrorMode.instant
                  }}
                  eventHandlers={{
                    onChangeHandler: (value: string, isValid: boolean) => {
                      const tempNewPaymentItems = [...newPaymentItems];
                      tempNewPaymentItems[index] = {
                        ...newPaymentItems[index],
                        feeName: value
                      };
                      setNewPaymentItems(tempNewPaymentItems);
                    }
                  }}
                />
              </div>
              <div className={styles['add-payment-modal__description']}>
                {
                  hasAccess([PERMISSIONS_LIST.paymentsFullAccess]) ?
                    <Select
                      options={[
                        { label: 'Select One', value: '' },
                        { label: 'Rent', value: PaymentItemType.rent },
                        { label: 'Cleaning', value: PaymentItemType.cleaning },
                        { label: 'Utilities', value: PaymentItemType.utilities },
                        { label: 'Insurance', value: PaymentItemType.insurance },
                        { label: 'Others', value: PaymentItemType.others }
                      ]}
                      value={
                        newPaymentItems[index].itemType
                          ? {
                            label: newPaymentItems[index].itemType,
                            value: newPaymentItems[index].itemType
                          }
                          : { label: 'Select One', value: '' }
                      }
                      onChange={(
                        value: ValueType<{ value: PaymentItemType; label: string }, false>
                      ) => {
                        const tempNewPaymentItems = [...newPaymentItems];
                        tempNewPaymentItems[index] = {
                          ...newPaymentItems[index],
                          itemType: value?.value || undefined
                        };
                        setNewPaymentItems(tempNewPaymentItems);
                      }}
                    /> :
                    <Select
                      options={[
                        { label: 'Select One', value: '' },
                        { label: 'Cleaning', value: PaymentItemType.cleaning },
                        { label: 'Utilities', value: PaymentItemType.utilities },
                        { label: 'Insurance', value: PaymentItemType.insurance },
                        { label: 'Others', value: PaymentItemType.others }
                      ]}
                      value={
                        newPaymentItems[index].itemType
                          ? {
                            label: newPaymentItems[index].itemType,
                            value: newPaymentItems[index].itemType
                          }
                          : { label: 'Select One', value: '' }
                      }
                      onChange={(
                        value: ValueType<{ value: PaymentItemType; label: string }, false>
                      ) => {
                        const tempNewPaymentItems = [...newPaymentItems];
                        tempNewPaymentItems[index] = {
                          ...newPaymentItems[index],
                          itemType: value?.value || undefined
                        };
                        setNewPaymentItems(tempNewPaymentItems);
                      }}
                    />
                }
              </div>
              <div className={styles['add-payment-modal__description']}>
                <InputText
                  data={{
                    value: newPaymentItems[index].feeValue,
                    errorMessage: 'Amount is required',
                    placeholder: 'Amount'
                  }}
                  config={{
                    maxRows: 1,
                    required: true,
                    type: InputTextType.number,
                    showErrorMode: InputTextErrorMode.instant
                  }}
                  eventHandlers={{
                    onChangeHandler: (value: string, isValid: boolean) => {
                      const tempNewPaymentItems = [...newPaymentItems];
                      tempNewPaymentItems[index] = {
                        ...newPaymentItems[index],
                        feeValue: Number(value)
                      };
                      setNewPaymentItems(tempNewPaymentItems);
                    }
                  }}
                />
              </div>

              <div
                onClick={() => {
                  console.log(newPaymentItems);
                  let tempNewPaymentItems = newPaymentItems.map((item) => ({ ...item }));
                  tempNewPaymentItems = tempNewPaymentItems.filter((item, itemIndex) => {
                    console.log(index, itemIndex);
                    return index !== itemIndex;
                  });
                  console.log(tempNewPaymentItems);
                  setNewPaymentItems(tempNewPaymentItems);
                }}
              >
                {' '}
                <DeleteIcon
                  fontSize="large"
                  color={'error'}
                  style={{ visibility: index === 0 ? 'hidden' : 'visible' }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles['add-payment-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.closeHandler();
          }}
          type={ButtonType.primaryInverted}
          tracking={{
            action: 'close',
            category: 'payments',
            label: 'add-payment-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Cancel
        </Button>
        <Button
          disabled={
            !newPaymentItems.every(
              (element) =>
                element.feeName && element.feeType && element.feeValue && element.itemType
            ) || !newPaymentDueDate.isValid
          }
          onClick={handleNewPaymentSubmit}
          tracking={{
            action: 'submit',
            category: 'payment',
            label: 'add-payment-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Add payment
        </Button>
      </div>
    </Modal>
  );
};
